<template>
  <div class="page">
    <el-card>
      <el-form class="checkedCodeContainer" label-width="10em">
        <div class="header">
          <span class="title">上传已发货文件列表</span>

          <div>
            <el-button type="success" @click="downloadTemplate" size="mini">下载模板</el-button>
            <div class="uploader" style="margin-left: 10px; display: inline">
              <input
                type="file"
                ref="expressDelivery"
                :accept="$store.getters['upload/fileAccept'].excel"
                @input="onCheckedFileChange"
              />
              <el-button type="success" size="mini">上传新文件</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="checkedCodeData.fileList"
          :row-class-name="tableRowClassName"
          fit
          center
          @expand-change="handleRowClick"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <div class="errorMessage" v-for="(i, n) in scope.row.remarkList" :key="n">
                <div class="row">行：{{ i.rowNum }}</div>
                <div class="msg">{{ i.errorMessage }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="fileName" label="名称" align="center"> </el-table-column>
          <el-table-column label="上传时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime | formatTime }}
            </template>
          </el-table-column>
          <el-table-column prop="totalCount" label="上传运单数" align="center"> </el-table-column>
          <el-table-column prop="successCount" label="成功数量" align="center"> </el-table-column>
          <el-table-column prop="tenantId" label="状态" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 0" type="info">审核中</el-tag>
              <el-tag v-if="scope.row.status === 1" type="danger">成功</el-tag>
              <el-tag v-if="scope.row.status === 2" type="success">部分成</el-tag>
              <el-tag v-if="scope.row.status === 3">失败</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="footer">
          <el-pagination
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            class="pagination"
          >
          </el-pagination>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'

import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [pagination],
  data: () => ({
    checkedCodeData: {
      id: '',
      file: null,
      fileName: '',
      fileList: []
    }
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    ...mapActions({}),
    formatRemarkList(val) {
      if (!val) {
        return []
      }
      try {
        let list = JSON.parse(val)
        return list.map(j => ({
          code: j.checkCode,
          row: j.rowNum,
          msg: j.errorMessage
        }))
      } catch (e) {
        return []
      }
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    handleRowClick(row) {
      console.log(row)
      this.$http
        .get('/boom-center-order-service/sysAdmin/expressDelivery/ship/upload/record/errorMessage/list', {
          params: { recordId: row.id }
        })
        .then(res => {
          this.checkedCodeData.fileList[row.index].remarkList = res
          this.$forceUpdate()
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    downloadTemplate() {
      window.open(
        'https://bm-mall-file.oss-cn-shenzhen.aliyuncs.com/pcMaterial/%E5%8F%91%E8%B4%A7%E6%A8%A1%E6%9D%BF.xlsx'
      )
    },
    getList() {
      this.$http
        .get('/boom-center-order-service/sysAdmin/expressDelivery/ship/upload/record/page', {
          params: {
            size: this.pageSize,
            page: this.currentPage
          }
        })
        .then(res => {
          this.checkedCodeData.fileList = res.list.map(i => {
            i.remarkList = this.formatRemarkList(i.remark)
            return i
          })

          this.total = res.total
        })
        .catch(err => {
          this.$message.error('请求失败')
        })
    },
    onCheckedFileChange(e) {
      if (e.target.files.length) {
        const file = e.target.files[0]

        if (file.size > 10240000) {
          this.$message.error('文件过大，大于10M，请分开上传')
          return
        }
        this.checkedCodeData.file = file
        this.checkedCodeData.fileName = file.name
        const loading = this.$loading()
        let formData = new FormData()
        formData.append('file', this.checkedCodeData.file)
        this.$http
          .post(`/boom-mix-biz-service/sysAdmin/expressDelivery/ship/upload`, formData)
          .then(res => {
            this.$message.success('上传文件成功')
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
          .finally(() => {
            this.$refs.expressDelivery.value = null
            loading.close()
            this.getList()
          })
      }
    }
  },
  created() {},
  mounted() {
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.page {
  width: 100%;
}
.fileList {
  text-align: center;
  .fileItem {
    width: 100%;
    display: flex;
    .column {
      flex-basis: 25%;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 5px;
      border-left: 1px solid var(--border);
      border-top: 1px solid var(--border);
      &:last-child {
        border-right: 1px solid var(--border);
      }
    }
    &:last-child {
      border-bottom: 1px solid var(--border);
    }
    &.header {
      background: var(--background);
      line-height: 30px;

      .column {
        padding: 0;
      }
    }
  }
  .tips {
    border: 1px solid var(--border);
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .uploader {
    position: relative;
    right: 0;
    input {
      .wrap;
      width: 100%;
      opacity: 0;
      position: absolute;
    }
  }
}
.checkedCodeContainer {
  .fileInfo {
    display: flex;
    align-items: center;
    .name {
      margin-right: 20px;
      color: var(--primary);
    }
  }
}
.uploader {
  position: relative;
  right: 0;
  input {
    .wrap;
    width: 100%;
    opacity: 0;
    position: absolute;
  }
}
</style>
